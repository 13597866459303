import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import logo from '../../assets/images/og-image.png';
import { ArticleContent } from '../../components/Blog/ArticleContent';
import { ArticleHero } from '../../components/Blog/ArticleHero';
import { IPost } from '../../components/Blog/ArticleItem';
import { ScPage } from '../../components/page/styled';
import Spinner from '../../components/spinner/index';
import { getPostById } from '../../helpers/blog';
import { MainLayout } from '../../layouts/main-layout';
import { AboutUsInfoSection } from '../../sections/AboutUs/AboutUsInfoSection';
import { AboutUsReadyToStartSaving } from '../../sections/AboutUs/ReadyToStartSaving';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface PostTemplateProps {
	pageContext: {
		id: string;
	};
}

const PostTemplate = (props: PostTemplateProps) => {
	const { id } = props.pageContext;

	const [post, setPost] = useState<IPost>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		async function fetchPost() {
			setIsLoading(true);
			const blogPost = await getPostById(id);
			setPost(blogPost);
			setIsLoading(false);
		}

		fetchPost();
	}, [id]);

	if (isLoading) return <Spinner />;

	return (
		<MainLayout>
			<Helmet>
				<title>{post.meta_title}</title>
				<meta property="og:title" content={post.meta_title} />
				<meta property="twitter:title" content={post.meta_title} />
				<meta name="description" content={post.meta_description} />
				<meta property="og:description" content={post.meta_description} />
				<meta property="twitter:description" content={post.meta_description} />
				<meta property="og:image" content={logo} />
				<meta property="og:image:url" content={logo} />
				<meta property="twitter:image" content={logo} />
				<meta content="summary_large_image" name="twitter:card" />
			</Helmet>
			<ScPage>
				<ArticleHero post={post}/>
				<ArticleContent post={post}/>
				<AboutUsInfoSection/>
				<AboutUsReadyToStartSaving/>
			</ScPage>
		</MainLayout>
	);
};

export default PostTemplate;
