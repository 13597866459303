import * as React from 'react';

import { ScContainer } from '../container/styled';
import { ScTop } from '../Top/styled';

import { IPost } from './ArticleItem';
import { ScArticleHeroWrapper, ScArticleLabel, ScArticleTime, ScArticleTitle } from './styled';

export const ArticleHero = ({ post }: { post: IPost }) => {
	const date = new Date(post?.published_at);

	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
	const formattedDate = date.toLocaleDateString('en-GB', options);
	return (
		<ScTop disableHeight>
			<ScContainer>
				<ScArticleHeroWrapper>
					<ScArticleLabel>Gogeta Blog</ScArticleLabel>
					<ScArticleTitle>{post?.title}</ScArticleTitle>
					<ScArticleTime>
						{post?.reading_time} min read | {formattedDate}
					</ScArticleTime>
				</ScArticleHeroWrapper>
			</ScContainer>
		</ScTop>
	);
};
