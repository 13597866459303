import * as React from 'react';

import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import { ScContainer } from '../container/styled';

import { IPost } from './ArticleItem';
import {
	ScArticleAuthorWrapper,
	ScArticleContent,
	ScArticleDivider,
	ScArticleImage,
} from './styled';
import { ScBlogListWrapper } from './styled';

export const ArticleContent = ({ post }: { post: IPost }) => {
	return (
		<ScBlogListWrapper>
			<Top />
			<div>
				<ScContainer>
					<ScArticleImage src={post?.feature_image} alt="feature_image" />
					<ScArticleContent dangerouslySetInnerHTML={{ __html: post?.html }} />
				</ScContainer>
				<ScArticleDivider />
				<ScContainer>
					<ScArticleAuthorWrapper avatar={post?.authors[0]?.profile_image}>
						{post?.authors[0]?.profile_image && <div className="avatar" />}
						<div>
							<h3>{post?.authors[0]?.name}</h3>
							<p>{post?.authors[0]?.bio}</p>
						</div>
					</ScArticleAuthorWrapper>
				</ScContainer>
			</div>
			<Bot />
		</ScBlogListWrapper>
	);
};
